//
// Buttons
//

// Base styles
//

.btn,
.r-btn {
    border-radius: $btn-border-radius;
    cursor: pointer;
    font-family: $btn-font-family;
    font-size: $btn-font-size;
    font-weight: $btn-font-weight;
    padding: space(.75) space(1.5);
    transition: opacity ease .5s;

    // Buttons modifiers //

    &.r--primary {
        background: color(secondary);
        background: $yellow-degrade;
        border: none;
        color: color(primary);
        //box-shadow: 10px 10px 60px color(dark,.33);

        &:hover {
            background: color(primary);
            color: color(light);
            text-decoration: none;
        }
    }

    &.r--secondary {
        background: color(primary);
        background: linear-gradient(250deg, rgba(0,56,119,1) 36%, rgba(9,32,86,1) 100%);
        box-shadow: 10px 10px 60px color(dark,.33);
        color: color(light);

        &:hover {
            opacity: .8;
        }
    }

    &.r--light {
        background-color: color(gray, lighter);
        color: color(primary);

        &:hover {
            background: color(gray, light);
        }
    }

    &.r--block {
        display: block;
        margin: space(.5) auto;
    }

    &.r--icon {
        font-size: font-size(h4);
        min-width: auto;
        padding: 0 space(.5);
    }

    &.r--facebook {
        background: color(facebook);
        color: color(light);
        margin-left: 45px;
        position: relative;

        @include smmdlg {
            margin-left: 60px;
        }

        &::before {
            background: color(facebook);
            border-radius: 50%;
            color: color(light);
            content: $icon-error;
            display: block;
            font-family: main;
            line-height: 44px;
            height: 44px;
            left: -60px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 44px;
        }
    }

    &.r--link {
        color: color(primary);
        font-family: $font-family-base;
        font-size: inherit;
        font-weight: bold;
        padding-left: space(.5);
        padding-right: space(.5);
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    &.r--min {
        min-width: 160px;
    }

    &:disabled,
    &.r--disabled {
        background: color(gray, lighter);
        border: 1px solid color(gray, light);
        color: color(gray);
        cursor: not-allowed;

        &:hover {
            background: none;
            color: initial;
        }

        &.r--icon {
            padding: space(.5) space(.5) space(.25);
        }
    }

    section.r--vertical & {
        margin-bottom: space(.5);
    }
}

.btn {
    background-color: color(button);
    color: color(primary);

    &:hover {
        //background: color(button, light);
        color: color(primary);
        opacity: .8;
    }

    &.webform-previous {
        background: color(gray, light);
        margin-right: space();
        width: auto;

        &:hover {
            //background: none !important;
            opacity: .8;
            text-decoration: underline;
        }
    }
}

.form-actions {
    .webform-previous {
        background: color(gray, light);
        margin-right: space();
        width: auto;

        &:hover {
            //background: none !important;
            opacity: .8;
            text-decoration: underline;
        }
    }
}
