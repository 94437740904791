//
// Block
//

// Block Modifier
//

r-box {
    &.r--table {
        r-box--body {
            padding: 0;
            text-align: center;
        }
    }

    &.r--list {
        // background: color(gray, lighter);
        // margin: auto;

        @include smmdlg {
            // float: right;
            // width: 33%;
        }

        li {
            padding: space(.25);
        }
    }

    // &.r--widget {
    //     @extend %column-12;
    //     @extend %column-sm-6;
    //     margin-bottom: space(2);
    //     padding-left: space();
    //     padding-right: space();
    //     position: relative;
    //     text-align: center;

    //     r-box--inner {
    //         background: color(gray, lighter);
    //         border-radius: 5px;

    //         &:hover {
    //             background: color(primary);

    //             r-icon {
    //                 color: color(primary);
    //             }

    //             a:not(.r-btn),
    //             r-p,
    //             r-p.r--description div,
    //             p,
    //             h2,
    //             r-box--expand {
    //                 color: color(light);
    //             }
    //         }
    //     }

    //     a {
    //         margin-top: 5px;
    //     }

    //     r-box--header {
    //         align-items: center;
    //         float: left;
    //         position: relative;
    //         width: 35%;

    //         @include smmdlg {
    //             display: block;
    //             float: none;
    //             padding-bottom: 0;
    //             width: auto;
    //         }
    //     }

    //     r-icon {
    //         background: color(light);
    //         border-radius: 50%;
    //         color: color(primary);
    //         display: block;
    //         font-size: font-size(h1);
    //         height: 5rem;
    //         line-height: 5rem;
    //         margin: 0 auto space(.5);
    //         width: 5rem;
    //     }

    //     r-box--expand {
    //         bottom: 0;
    //         left: 0;
    //         position: absolute;
    //         right: 0;
    //         top: 0;

    //         &::before {
    //             height: 40px;
    //             margin-top: -20px;
    //             line-height: 40px;
    //             position: absolute;
    //             right: space(.5);
    //             text-align: center;
    //             top: 50%;
    //             width: 40px;
    //         }
    //     }

    //     r-box--body {
    //         float: left;
    //         margin-bottom: 0;
    //         padding-top: space(.5);
    //         text-align: left;
    //         width: 60%;

    //         @include smmdlg {
    //             float: none;
    //             text-align: center;
    //             width: auto;
    //         }
    //     }
    // }

    // &.famille-membre {
    //     table {
    //         border: 1px solid color(gray, light);
    //     }
    // }

    // &.famille-guest {
    //     .r-table--head {
    //         th {
    //             color: color(primary);
    //         }
    //     }

    //     &.-is-expired {
    //         .r-table--head {
    //             th {
    //                 color: color(gray);
    //             }
    //         }
    //     }
    // }

    r-footer & {
        r-box--body {
            padding: 0;
        }

        p {
            margin: 0;
        }
    }
}

.r-bloc-intro {
    font-family: $font-family-title;
    font-size: 22px;
    font-weight: bold;
    margin: 0 auto space(1.5);
    padding: 0 space();
    text-align: center;

    @include mdlg {
        font-size: 40px;
        max-width: 1180px;
        width: 70%;
    }
}

.r-section {
    r-box--inner {

        @include mdlg {
            margin: auto;
            width: 860px;
        }

    }

    h2 {
        font-family: $font-family-title;
        font-size: 1.5rem;
        font-weight: bold;

        @include mdlg {
            font-size: font-size(h2);
        }
    }
}

.r--offrir {
    background: color(blue-light);

    r-box--inner {
        align-items: center;
        column-gap: space();
        display: flex;
        justify-content: center;
        padding: space(2) space();

        @include mdlg {
            column-gap: space(2);
            padding: space(3);
        }
    }

    h3 {
        flex: 1;
        font-weight: normal;
        margin: 0;

        @include xssm {
            font-size: 1.2rem;
        }
    }
}

.r--edito {
    background: $blue-degrade;
    padding: space(2) space(1.5);

    @include mdlg {
        padding: space(4);
    }

    r-box--inner {
        @include smmdlg {
            align-items: center;
            display: flex;
        }
    }

    h2 {
        color: color(light);
    }

    figure {
        flex: 2;
        margin: 0;
        order: 2
    }

    img {
        border-radius: 10px;
        border: 1px solid color(light);
        box-shadow: 40px 40px 80px #0038774D;
    }

    r-box--body {
        color: color(light);
        flex: 3;
        order: -1;
        padding: 0 space();

        @include xssm {
            padding: space() 0;
        }
    }

    address {
        font-style: normal;
        font-weight: bold;
    }
}

.r-list {
    &.r--service {
        list-style: none;
        padding: 0;

        @include smmdlg {
            column-gap: space(4);
            display: flex;
            justify-content: center;
        }

        li {
            align-items: center;
            display: flex;

            @include smmdlg {
                margin-bottom: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            r-icon {
                display: block;
                font-size: font-size(h3);
                margin: space();

                @include smmdlg {
                    margin: space(.5);
                }
            }
        }
    }
}

.r--offers-link {
    background: $blue-degrade;
    display: flex;
    flex-wrap: wrap;
    gap: space();
    justify-content: center;
    padding: space(2) 0;

    @include smmdlg {
        padding: space(4) 0;
    }

    h2 {
        color: color(light);
        margin-bottom: space();
        text-align: center;
        width: 100%;
    }

    a {
        align-items: center;
        border: 1px solid color(light);
        border-radius: 10px;
        color: color(light);
        display: flex;
        flex-direction: column;
        font-size: .9rem;
        justify-content: center;
        max-width: 280px;
        padding: space(1.5) space(.75);
        row-gap: space(1.5);
        text-decoration: none;
        width: calc((100% / 2) - #{space(2)});

        @include mdlg {
            font-size: 1.5rem;
            padding: space(2) space(1.5);
            width: calc((100% / 4) - #{space(3)});
        }

        &:hover {
            background: color(light);
            color: color(primary);
        }
    }

    r-icon {
        font-size: 60px;

        @include xssm {
            font-size: 80px;
        }
    }
}

.r-box--raisons {
    counter-reset: article;
    display: flex;
    flex-wrap: wrap;
    gap: space(.5);
    justify-content: space-between;
    margin: space(4) auto;
    max-width: calc((#{$offre-width} * 3) + (#{space(1.5)} * 4));
    padding: 0 space(2);
    width: 100%;

    @include smmdlg {
        gap: space(2.5);
    }

    h2 {
        font-family: $font-family-title;
        font-size: font-size(h2);
        font-weight: bold;
        text-align: center;
        width: 100%;
    }
}

.r-raison {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;

    @include sm {
        width: calc((100% / 2) - #{space(1.5)});
    }

    @include mdlg {
        width: calc((100% / 3) - #{space(2.5)});
    }

    r-box--header {
        padding: space() space() 0;
        position: relative;
        z-index: 1;
    }

    img {
        border-radius:  10px 10px 0 0;
    }

    figure {
        margin: 0;

        &::before {
            background-color: color(blue-light);
            border-radius: 50px;
            bottom: -50px;
            counter-increment: article;
            content: counter(article);
            color: color(pink);
            font-family: $font-family-title;
            font-size: 70px;
            font-weight: bold;
            height: 100px;
            left: 0;
            line-height: 100px;
            margin: auto;
            position: absolute;
            right: 0;
            width: 100px;
        }
    }

    r-box--body {
        background-color: color(blue-light);
        border-radius: 10px;
        box-shadow: 0 0 20px color(gray, light);
        position: relative;

        @include smmdlg {
            height: 100%;
        }
    }

    h4 {
        font-family: $font-family-title;
        font-size: 1.5rem;
        font-weight: normal;
    }
}

.gr-last-step {
  padding: space(2) 0;

  r-main--content {
    justify-content: center;
    max-width: 1000px;
    margin: space(3) auto space();
  }

  r-box {
    background: linear-gradient(0deg, color(light) 70%, darken(color(primary), 4%) 70%, color(primary) 100%);
    max-width: 400px;
    margin: 0 auto;
    box-shadow: 0 space(.5) 20px rgba(color(primary), .1);
    margin-bottom: space(2);
  }

  r-box--header {
    color: color(light);
    font-weight: 800;
    font-size: 18px;
    padding-top: space(2);
  }

  r-box--body {
    color: color(primary);

    p {
      margin: space(2) 50px;
    }
  }

  p {
    max-width: 100%;
  }

  .r-list {
    padding: 0;
    margin: 0;

    &.r--service {
      display: flex;
      flex-wrap: wrap;
      max-width: 140px;
      justify-content: space-between;
      margin: 0 auto;

      li {
        width: 40px;
        flex: 0 0 40px;
        margin: 0 5px 30px;
      }
    }

    li {
      list-style: none;
      margin-bottom: space();
      padding: 0;
    }
  }
}

.r-faq-list {
    background: linear-gradient(180deg, rgba(255,255,255,1) 36%, rgba(239,246,255,1) 100%);
    padding: space(2) space(1.5);

    @include mdlg {
        padding: space(4) 0;
    }

    dt {
        font-weight: bold;
        padding-right: space(2);
    }

    dd {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        margin:0;
        overflow: hidden;
        padding-right: space(2);
    }

    .r-faq-list--ask {
        border-bottom: 1px solid color(primary);
        padding: space(.75) 0;
        position: relative;

        &:last-of-type {
            border: none;
        }

        &::after {
            content: $icon-angle-arrow-down;
            cursor: pointer;
            color: black;
            display: block;
            font-family: 'main';
            font-size: 1.5rem;
            position: absolute;
            right: 0;
            top: 10px;
            transition: all .2s;
        }

        &.-is-open {
            &::after {
                transform: rotate(180deg);
            }

            dd {
                display: block;
            }
        }
    }

    .r-btn {
        @include xssm {
            display: block;
            margin: auto;
            text-align: center;
            width: 210px;
        }
    }
}


