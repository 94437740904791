//
// Layout > Header
//

r-header {
    align-items: center;
    background: color(primary);
    display: grid;
    grid-template-areas:
            ". . slogan help sso";
    grid-template-columns: minmax(0, 50px) minmax(0, 50px) minmax(0, auto) minmax(0, 50px) minmax(0, 50px);
    padding: space() space(.5);
    text-align: center;

    @include mdlg {
        grid-template-columns: minmax(0, 300px) minmax(0, 300px) minmax(0, auto) minmax(0, 300px) minmax(0, 300px);
    }

    p {
        margin: 0;
    }

    r-box--body {
        padding: 0;
    }

    // .node-type-webform & {
    //     background: color(primary);
    //     grid-template-areas:
    //         "logo slogan sso";
    //     grid-template-rows: 38px;
    // }
}

//
// GRID
//
.r-logo {
    grid-area: logo;
}


.r--menu-m-lesoir{
  grid-area: menu;
}

.r--menu-webform-lesoir {
  grid-area: menu;
  z-index:102;
  width: 180px;

  & + .r--menu-m-lesoir {
    display: none;
    // padding-left: 11rem;
    // @include mdlg {
    //     padding-left: 13rem;
    // }

    // &::before {
    //   @include mdlg {
    //     color:color(primary);
    //     content: '|';
    //     margin-right: 1rem;
    //   }
    // }
  }
  
  .node-type-webform & {
      display: block;
  }
}

r-box.r--sso {
    grid-area: sso;
}

.r--info-header {
  color: color(primary);
  align-items: center;
  grid-area: info;
  margin: 0 20px 0 0;
  padding: 0;
  display: none;

  @include mdlg {
    display: flex;
  }
}

.r--tel {
  list-style: none;
  margin-right: space();

  &:before {
    content:$icon-phone;
    font-family: 'main';
    margin-right: space(.5);
    vertical-align: middle;
  }
}

.r--email {
  list-style: none;

  &:before {
    content:$icon-envelope;
    font-family: 'main';
    margin-right: space(.5);
    vertical-align: middle;
  }
}

//
// Contenu header
//

.r-logo {
    display: block;
    margin: space(.25);
}

.r-logo--img {
    height: 22px;
}

.r-slogan {
    grid-area: slogan;
}

.r-slogan--img {
    height: 18px;
    margin-left: space(.75);
    display: none;

    @include mdlg {
        display: inline;
    }
}

.r-return {
  color: color(gray,lighter);
  display: block;
  font-family: $font-family-alt;
  margin-right: auto;
  padding: space(.5);   
  
  &:hover {
    color: color(gray,lighter);
    text-decoration: underline;
  }
}

.r-help {
    grid-area: help;
    justify-self: right;

    @include smmdlg {
        position: relative;
    }

    .r-btn--action {
        align-items: center;
        border-radius: 50px;
        column-gap: space(.5);
        cursor: pointer;
        display: flex;
        line-height: 1;

        @include mdlg {
            border: 1px solid color(light);
            color: color(light);
            padding: space(.5) space();
        }

        &:hover {
            @include mdlg {
                background: color(light);
                color: color(primary);
            }
        }

        span {
            font-size: .9rem;
            font-weight: bold;

            @include xssm {
                display: none;
            }
        }

        r-icon {
            font-size: 1.5rem;

            @include xssm {
                color: color(light);
                font-size: 1.9rem;
            }
        }
    }
}

.r-help--description {
    background: color(light);
    border: 1px solid color(primary);
    border-radius: 5px;
    bottom: space(-1);
    box-shadow: 0 0 100px color(blue-gray);
    display: none;
    font-size: .9rem;
    left: 0;
    max-width: 90vw;
    position: absolute;
    text-align: left;
    transform: translateY(100%);
    width: 360px;
    z-index: 100;

    @include mdlg {
        bottom: space(-2);
        left: -104px; // = (description - bouton)/2
    }

    @include sm {
        left: auto;
        right: -39px;
    }

    @include xs {
        left: auto;
        right: 19px;
    }

    &::before {
        content: "";
        background-color: color(primary);
        background: url('sites/all/themes/custom/boutique/bootstrapCarbonMarketingLeSoir/img/arrow-round.svg');
        background-repeat: no-repeat;
        //clip-path: polygon(50% 0, 0 100%, 100% 100%);
        display: block;
        height: 12px;
        position: absolute;
        top: -11px;
        right: 45px;
        width: 19px;

        @include mdlg {
            left: 0;
            margin: auto;
            right: 0;
        }
    }

    &.-is-open {
        display: block;
    }

    ul {
        margin: 0;
        padding: 0;
    }

    li {
        align-items: center;
        border-bottom: 1px solid color(blue-gray);
        column-gap: space(1.5);
        display: flex;
        list-style: none;
        padding: space() space(2);

        &:last-child {
            border: none;
        }
    }

    r-icon {
        font-size: 50px;
    }

    a {
        font-weight: bold;
        text-decoration: underline;
    }

    .r-help--phone {
        a {
            @include mdlg {
                cursor: default;
                pointer-events: none;
            }
        }
    }
}
