// -----------------------------------------------------------------------------
// Base
// -----------------------------------------------------------------------------

body {
    -webkit-font-smoothing:auto;
    color: color(primary);
}

// Headings
//

h1 {
    @include font(h1);
    color: color(primary);
    font-family: $font-family-base;
    font-size: 5vw;
    font-weight: normal;
    margin: space() 0;
    text-align: center;

    @include smmdlg {
        font-size: font-size(h2);
        margin: space(2) 0;
    }
}

h2 {
    @include font(h2);
    color: color(primary);
    font-family: $font-family-base;
    font-size: font-size-dynamic(18px, 24px, xs);
    font-weight: normal;
    margin: 0;

    @include smmdlg {
        font-size: font-size(h3);
        margin: 0;
    }

    strong {
        color: color(primary);
    }
}

h3 {
    @include font(h3);
    margin: 0 0 space();
}

// Text
//

p {
    max-width: 600px;
}

// r-p {
//     max-width: 600px;

//     em {
//         color: color(primary);
//     }
// }

a {
    &:hover {
        color: color(primary);
    }
}
