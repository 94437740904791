.onglets-paiement,
.message-renew-card {
  max-width: 500px;
  margin: auto;

  form {
    @include mdlg {
      display: flex;
      flex-wrap: wrap;
      gap: .75rem;
    }

    .form-group {
      width: 100%
    }
  }

  .btn {
    display: block;
    font-size: space();
    margin: 0 auto;
    padding: 12px space();

    @include mdlg {
      font-size: space(1.2);
      padding: 12px 24px;
    }

    :hover {
      color: color(primary);
      opacity: .8;
    }
  }

  h2 {
    font-size: space(1.2);
    margin: 0 auto 1rem;
    text-align: center;
  }

  label {
    margin: 0;
  }

  .r-btn {
    border-radius: $btn-border-radius;
    font-family: $btn-font-family;
    font-size: $btn-font-size;
    font-weight: $btn-font-weight;
    padding: space() space(2);
    transition: opacity ease .5s;

    // Buttons modifiers //

    &.r--primary {
      background: color(secondary);
      background: $yellow-degrade;
      border: none;
      color: color(primary);
      display: inherit;
      margin: auto;
      //box-shadow: 10px 10px 60px color(dark,.33);
      text-align: center;
      width: auto;

      &:hover {
        opacity: .8;
      }
    }
  }

  .form-text {
    background-clip: padding-box;
    background-color: $white;
    border: 1px solid color(primary);
    border-radius: 10px;
    color: color(gray, dark);
    display: block;
    line-height: 1.5;
    margin: 0;
    padding: space();
    width: 100%;

    &.ccInvalid,
    &.cvcInvalid,
    &.cardholderInvalid {
      border: 1px solid red;
    }

    &.ccValid,
    &.cvcValid,
    &.cardholderValid {
      border: 1px solid green;
    }

  }


  .messages {
    padding: 0 space(.5);
    width: 100%;
  }

  .montant-tab {
    margin-top: space();
    text-align: center;
  }

  .panel-heading {
    margin-left: -15px;
    margin-right: -15px;
  }

  .panel .panel-body {
    padding: 0;
  }

  #submit {
    display: block;
    margin: auto;
  }

  .sepa-accept {
    display: flex;
    font-size: .9rem;

    input {
      appearance: none;
      //margin-right: space();

      &:checked {
        cursor: pointer;

        &:before {
          align-items: center;
          background-color: color(secondary);
          border-color: color(secondary);
          content: $icon-check;
          display: flex;
          font-size: .7rem;
          justify-content: center;
        }
      }

      &:before {
        border: 1px solid color(blue-gray);
        content: '';
        border-radius: 4px;
        color: white;
        display: block;
        font-family: 'main';
        height: 1.5rem;
        padding: 0;
        margin-right: 1rem;
        width: 1.5rem;
      }
    }
  }

  .webform-component-textfield.validDate {
    display: inline-block;
    margin-right: 1px;

    width: calc(100% - 120px);

    @include smmdlg {
      width: calc(50% - 8px);
    }

    &.monthyearInvalid {
      &>div {
        border: 1px solid red;
      }
    }

    &.monthyearValid {
      &>div {
        border: 1px solid green;
      }
    }


    &+.webform-component-textfield {
      display: inline-block;
      margin-right: 0;
      width: 104px;

      @include smmdlg {
        width: calc(50% - 8px);
      }
    }

    &>label {
      margin: 0;
      width: 100%;
    }



    div {
      background-clip: padding-box;
      background-color: $white;
      border: 1px solid color(primary);
      border-radius: 10px;
      color: color(gray, dark);
      display: block;
      line-height: 1.5;
      margin: 0;
      padding: space();
      width: 100%;

      select {
        display: inline-block;
        border: none;
        background: none;
      }

      label {
        display: inline-block;
        margin: 0;
      }
    }
  }

  .webform-component-textfield {
    position: relative;

    &>img,
    &>svg {
      width: 40px;
      position: absolute;
      bottom: space(.5);
      right: space();
      z-index: 2222222;
    }
  }



  // .creditCardMaster.ccNotMastercard,
  // .creditCardVisa.ccNotVisa,
  // .ccNotMastercard,
  // .ccNotVisa {
  //   &~.creditCard {
  //     opacity: 1;
  //   }

  //   &~.creditCardVisa,
  //   &~.creditCardMaster {
  //     opacity: 1;
  //   }
  // }

  .validNumber {
    .creditCardVisa {
      opacity: 0;
    }

    .creditCardMaster {
      opacity: 0
    }

    .ccInvalid {
      &~.creditCard {
        opacity: 1 !important;
      }

      &~.creditCardVisa {
        opacity: 0 !important;
      }

      &~.creditCardMaster {
        opacity: 0 !important;
      }

      &.ccMastercard {
        &~.creditCardMaster {
          opacity: .4 !important;
        }

        &~.creditCardVisa,
        &~.creditCard {
          opacity: 0 !important;
        }
      }

      &.ccVisa {
        &~.creditCardVisa {
          opacity: .4 !important;
        }

        &~.creditCardMaster,
        &~.creditCard {
          opacity: 0 !important;
        }
      }
    }

    .ccVisa {
      &~.creditCardVisa {
        opacity: 1;
      }

      &.ccInvalid {
        &~.creditCardVisa {
          opacity: .4;
        }
      }

      &~.creditCard,
      &~.ccMastercard {
        opacity: 0;
      }
    }

    .ccMastercard {
      &~.creditCardMaster {
        opacity: 1;
      }

      &.ccInvalid {
        &~.creditCardMaster {
          opacity: .4;
        }
      }

      &~.creditCard {
        opacity: 0;
      }
    }
  }
}

.onglets-paiement {
  .tab-content {
    .payment-form-container {
      background-color: color(blue-light);
      border: 1px solid color(primary);
      border-top-width: 0;
      border-radius: 0 0 space(.5) space(.5);
      margin-bottom: space();
      padding: space() space() space(2);
    }

    .choix-onglet {
      background-color: color(blue-light);
      //border: 1px solid color(gray, lighter);
      border-radius: space(.5);
      color: color(primary);
      font-size: font-size(h5);
      margin-bottom: space();
      padding: space();


      &.tab-payment-PPU,
      &.tab-payment-PPR {
        background-image: none;
        width: auto;

        a {
          text-indent: 0;

          &:after {
            background: url('/sites/all/themes/custom/boutique/bootstrapMarketing/img/paypal.svg') no-repeat;
            background-size: auto 25px;
            background-position: center center;
            content: "";
            height: 38px;
            margin-left: auto;
            margin-right: space();
            width: 98px;

            @include xs {
              margin-right: 0;
            }
          }
        }
      }

      &.tab-payment-CDB,
      &.tab-payment-CDB2 {
        //background: none;
        width: 100%;

        a {
          &:after {
            background-image: url('/sites/all/themes/custom/boutique/bootstrapMarketing/img/bancontact_logo.svg');
            background-position: left top;
            background-repeat: no-repeat;
            background-size: auto 32px;
            content: "";
            display: inline-block;
            height: 38px;
            margin-left: auto;
            margin-right: space();
            width: 54px;

            @include xs {
              margin-right: 0;
            }
          }
        }
      }

      a {
        align-items: center;
        //border: 1px solid color(gray, light);
        //box-shadow: 0 0 1px color(gray);
        color: color(primary);
        // display: block;
        display: flex;
        height: 40px;
        padding: 0 0 0 space(3);
        position: relative;
        width: 100%;

        @include mdlg {
          padding: 0 space() 0 space(3);
        }

        &:before {
          background-color: $white;
          border: 1px solid color(blue-gray);
          border-radius: space(2);
          content: "";
          font-family: main;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-style: normal;
          font-variant: normal;
          font-weight: normal;
          font-size: space(.5);
          height: space(1.5);
          left: space(.25);
          margin-right: space();
          position: absolute;
          text-align: center;
          text-decoration: none;
          text-transform: none;
          width: space(1.5);

          @include xs {
            margin-right: 0;
          }
        }

        &:hover {
          text-decoration: none;
        }
      }

      &.tab-payment-TWK,
      &.tab-payment-SEPA {
        a {

          &:after {
            background-image: url('/sites/all/themes/custom/boutique/bootstrapMarketing/img/sepa.svg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: auto 22px;
            content: "";
            display: inline-block;
            margin-left: auto;
            margin-right: space();
            height: 38px;
            vertical-align: text-bottom;
            width: 65px;

            @include xs {
              background-size: auto 18px;
              margin-right: 0;
            }
          }
        }

        .tab-logo-TWK {
          align-self: baseline;
          margin-left: 5px;
          text-indent: -999px;
        }
      }

      &.tab-payment-OGU,
      &.tab-payment-OGU2,
      &.tab-payment-OGR,
      &.tab-payment-OGR2,
      &.tab-payment-OGR_FREE {
        background-image: none;
        width: 100%;

        a {
          text-indent: 0px;

          &:after {
            background: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/img/credit_card.svg') no-repeat;
            background-position: center center;
            background-size: auto 25px;
            content: '';
            display: block;
            height: 38px;
            margin-left: auto;
            margin-right: space();
            width: 73px;

            @include xs {
              margin-right: 0;
            }
          }
        }
      }
    }

    .tab-pane {
      display: none !important;

      .force-open & {
        display: block !important;
      }
    }

    .is-open {
      //background-color: color(gray);
      border: 1px solid color(primary);
      border-bottom-width: 0;
      border-radius: space(.5) space(.5) 0 0;
      margin-bottom: 0;


      a {
        //color: color(light);

        &:before {
          background: color(primary);
          border: 3px solid color(blue-light);
          content: "";
          outline: 1px solid color(primary);
        }
      }

      +.tab-pane {
        display: block !important;
      }

      // .lien-choix-onglet {
      //   color: color(primary);
      //   font-size: space(1.5);
      // }
    }

    >.active {
      display: block !important;
    }
  }

  .webform-component-textfield {
    display: inline-block;
    margin-bottom: space();
    width: 100%;
  }

  #tab-payment-OGU,
  #tab-payment-OGR,
  #tab-payment-OGR2,
  #tab-payment-OGR_FREE {
    h2 {
      display: none;
    }
  }
}

.ccInvalid+.cardI::before {
  background: url(/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/img/cb.svg) center center no-repeat;
  background-size: auto;
  background-size: auto 25px;
  content: '';
  display: block;
  height: 38px;
  margin-left: auto;
  margin-right: 16px;
  width: 73px;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 300;

  @include xs {
    margin-right: 0;
  }
}

.tab-payment-PPU,
#tab-payment-PPU,
.tab-payment-PPR,
#tab-payment-PPR {
  button {
    background: none;
    padding: 0;

    &.btn {
      background-color: color(button);
      color: color(gray, darker);
      cursor: pointer;
      padding: space(.75) space(1.5);
    }
  }
}