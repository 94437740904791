//
// Layout > Footer
//

r-footer {
    background: color(gray, lighter);
    padding: 5px;

    @include smmdlg {
        display: flex;
        justify-content: end;
        align-items: center;
    }

    .r--menu-menu-footer-le-soir {
        margin: 0;
        display: block;
        
        @include mdlg {
            display: flex;
            align-items: center;
        }

        .r-menu--link {
            cursor: pointer;
            font-weight: 300;
            padding: space(.25) space(.5);
            
        }
    }
}

.r-footer--copyright {
    font-family: $font-family-alt;
    padding: space(.25);
}

.embeddedServiceHelpButton {
    display: none !important;

    @include smmdlg {
        display: inherit !important;
    }
}
