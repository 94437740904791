@font-face {
    font-family: 'SourceSans3';
    font-style: normal;
    font-weight: bold;
    src:  url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Source_Sans_3/SourceSans3-Bold.woff2') format('woff2');
}

@font-face {
    font-family: 'SourceSans3';
    font-style: normal;
    font-weight: normal;
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Source_Sans_3/SourceSans3-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'SourceSans3';
    font-style: normal;
    font-weight: 300;
    src:  url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Source_Sans_3/SourceSans3-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'STIXTwoText';
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/STIX_Two_Text/STIXTwoText-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'STIXTwoText';
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/STIX_Two_Text/STIXTwoText-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'economica';
    font-style: normal;
    font-weight: normal;
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/economica/Economica-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'shadows';
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/shadows/ShadowsIntoLightTwo-Regular.woff2') format('woff2'),
    url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/shadows/ShadowsIntoLightTwo-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
