//
// SSO
//

.r--toolbar_sso {
    r-box--body {
        padding: 0;
    }
}

.r--sso {
    background: color(primary);
    color: color(light);
    display: flex;
    font-size: font-size(base);
    line-height: 1;
    margin-left: space();
    position: relative;

    @include mdlg {
        padding: 0 space();
    }

    a {
        color: color(light);
    }
}

.r--sso-non-connecte {
    align-items: center;
}

.r--sso-connecte {
    align-items: flex-end;
}

.r-sso-logo {
    margin-right: auto;
}

.r--sso-user-menu {
    position: relative;

    label {
        align-items: center;
        color: color(light);
        cursor: pointer;
        display: flex;

        span {
            font-weight: normal;
            margin-left: 10px;

            @include xssm {
                display: none;
            }
        }
    }

    r-icon {
        font-size: 26px;
    }

    a {
        display: flex;
        align-items: center;
        column-gap: space(.5);
        font-weight: bold;
        text-decoration: none;

        &:hover {
            span {
                text-decoration: underline;
            }
        }

        span {
            @include xssm {
                display: none;
            }
        }

        @include xssm {
            display: inline-block;
            //font-size: 0;
            font-weight: normal;
            text-align: left;
        }
    }
}

.r--sso-menu {
    background: color(light);
    box-shadow: 2px 2px 2px #ccc;
    color: color(dark);
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 45px;
    width: 250px;
    z-index: 10;

    li {
        background: color(gray, lighter);
        margin-bottom: 1px;
    }

    .r-sso-compte {
        @include xs {
            padding: space(.75) 0;
        }
    }

    a {
        background: color(gray, lighter);
        color: #575757;
        display: block;
        text-decoration: none;
        padding: space(.5) 15px;

        &:hover {
            background: #ededed;
            color: #575757;
            text-decoration: none;
        }
    }
}

.r-sso-compte {
    background: color(light);
    color: color(primary);
    padding: 15px;

    strong {
        color: #242424;
    }
}

.r-sso-checkbox {
    display: none;
}

.r-sso-checkbox:checked~.r--sso-menu {
    display: block;
}

.r-sso-close {
    display: none;
}

.r-sso-checkbox:checked~label .r-sso-close {
    display: block;
}

.r-sso-checkbox:checked~label .r-sso-open {
    display: none;
}
