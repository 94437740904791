//
// Forms
//

// Container
//

// .r-form {
//     padding: space(.75) space();

//     @include smmdlg {
//         padding: space(.75);
//     }

//     &.r--panel-right {
//         @include smmdlg {
//             width: 66%;
//         }
//     }
// }

// Core styles
//
.node-type-webform {
  h1 {
    display: none;
  }
}

r-form--group {
  max-width: 600px;
  position: relative;

  // Form group modifiers
  //

  &.r--highlight {
    background: color(primary);
    color: color(light);
    padding: space();

    input {
      background: color(light);
    }

    a {
      color: color(light);
      text-decoration: underline;
    }
  }

  &.r--address-street {
    @extend %column-sm-8;
    padding-right: space();
  }

  &.r--address-num,
  &.r--address-box {
    @extend %column-sm-2;
  }

  &.r--address-zip,
  &.r--address-city {
    @extend %column-sm-6;
  }

  &.r--address-num,
  &.r--address-zip {
    padding-right: space();
  }

  &.webform-component--civilite {
    .r--radio {
      display: inline-block;
      width: auto;

      +.r--radio {
        margin-left: space(2);
      }

      label {
        margin-bottom: 0;
      }
    }
  }

  &.webform-component--user-profil-naissance {
    display: flex;

    @include xs {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &.r--checkbox {
    color: color(primary);
  }

  &.r--checkbox,
  &.r--radio {
    width: auto;
  }

  .cm-date & {
    margin-left: space();

    @include xs {
      margin: 0 auto space(.5);
      width: calc(100% / 3.1);
    }
  }

  p {
    margin: 0;
  }
}

r-form--text {
  color: color(gray);

  .r-form--action {
    position: absolute;
    right: 0;
    text-decoration: underline;
    top: 0;
  }
}

.form-text {
  @extend %form--input-text;
}

.form-text,
.r-form--input-text {
  border-radius: 5px;

  &:focus {
    background-color: $forms-input-focus-bg-color;
    border-color: $forms-input-focus-border-color;
  }

  &::placeholder {
    opacity: 1;
  }

  &[readonly],
  &[disabled] {
    background: color(gray, light);
    opacity: $components-opacity;
  }
}

label,
.r-form--label {
  display: inline-block;
  // font-weight: bold;
  line-height: 1.5;

  .small & {
    font-size: font-size(small);
    line-height: 2;
  }

  .form-required {
    color: color(error);
    font-weight: bold;
  }

  .cm-date & {
    justify-content: flex-start;

    @include smmdlg {
      min-width: 140px;
    }

    @include xs {
      width: 100%;
    }
  }
}

.r-form--select,
.r-form--input-date {
  background-position: right space(.5) center, 100% 0;
  background-size: 25px, 2.5rem 2.5rem;
  border-radius: 5px;

  &:focus {
    background-color: $forms-input-focus-bg-color;
    border-color: $forms-input-focus-border-color;
  }
}

.r-form--input-date {
  @extend %form--select;
}

.r-form--input-radio,
.r-form--input-checkbox {
  margin-top: space(.5);

  &:first-child {
    margin-top: 0;
  }

  &.r--inline {
    margin-top: 0;

    @include smmdlg {
      display: inline-block;
      margin-top: 0;
    }

    +.r--inline {
      margin-top: space(.5);

      @include smmdlg {
        margin-left: space(2);
      }
    }
  }

  &.r--obligatoire {
    color: color(error);
  }
}

// Drupal

.form-actions {
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  text-align: justify;

  @include xs {
    justify-content: space-evenly;
  }

  input {
    border-radius: $btn-border-radius;
    font-family: $btn-font-family;
    font-size: $btn-font-size;
    font-weight: $btn-font-weight;
    padding: space(.5) space(1.5);
    background-color: color(secondary);
    color: color(primary);
    border: none;
    cursor: pointer;

    &:hover {
      background: color(secondary, light);
    }

    @include smmdlg {
      padding: $btn-padding;
    }
  }

  p {
    max-width: 700px;

    .gr-one-col &,
    .gr-two-col & {
      text-align: left;
    }
  }
}

.webform-component--numero-client {
  max-width: none;
}

.webform-component--numero-client--user-numero-abo {
  display: flex;
  align-items: center;

  label {
    white-space: nowrap;
    margin-bottom: 0;
    margin-right: space();
  }

  input {
    border-radius: 5px;
    border: 1px solid $forms-input-border-color;

    &:focus {
      border-color: $forms-input-focus-border-color;
      outline: none;
      box-shadow: none;
      background: $forms-input-focus-bg-color;
    }
  }
}

//login
.cm-logout {
  display: none;
}

// barre d'étapes
.cm-progressbar {
  &:first-child {
    .cm-progressbar-page {
      // padding: space(.5) space(2) space(.25);

      &.current {
        background: color(light);
        border-bottom: 1px solid #e7e7e7;
        border-top: 0;

        @include smmdlg {
          font-size: 2.4rem;
        }
      }
    }
  }
}

.cm-progressbar-page {
  border-top: 1px solid #e7e7e7;
  font-size: 2rem;
  line-height: 3rem;
  padding: space(.25) 10%;

  @include smmdlg {
    font-size: 3rem;
    padding: space() space(2);
  }

  &.current {
    border-top: 1px solid #e7e7e7;
    color: color(gray, dark);
  }
}


body.node-type-formulaire-perso {
  r-wrapper {
    background: color(light);
  }
}

.cm-form-right {
  .node-formulaire-perso & {
    box-shadow: none;
    background: none;
    font-family: $font-family-base;
    color: color(gray, dark);
    font-size: font-size(h5);

    a,
    h2 {
      color: color(primary);
    }

    a {
      border-bottom: 1px solid color(primary);

      &:hover,
      &:focus {
        text-decoration: none;
        border: none;
      }
    }

    h2 {
      font-size: font-size(h2);
      font-family: $font-family-base;
      border-bottom: 2px solid rgba(color(primary), 0.1);
      font-weight: bold;
      padding-bottom: space(.5);
      margin-bottom: space();
    }

    h3 {
      font-size: font-size(h4);
      font-family: $font-family-base;
      margin-top: space(2);
    }

    .form-type-select,
    .webform-component-select {
      background: color(gray, lighter);
    }

    input,
    textarea,
    select,
    label {
      font-family: $font-family-base;
      font-size: font-size(base);
    }

    .cm-form-content {
      display: flex;
      flex-direction: column;
    }

    .webform-component--markup-journal-le-soir,
    .webform-component--markup-soirmag {
      order: 10;
      margin-top: space(4);
    }

    .webform-component--file {
      r-form--text {
        display: none;
      }

      label {
        margin-bottom: 0;

        &:before {
          font-family: 'main';
          content: $icon-attached;
          margin-right: 5px;
        }
      }

      #edit-submitted-file-remove-button {
        background: color(green);
        font-family: $font-family-base;
        border-radius: 30px;
        color: color(light);
        cursor: pointer;

        &:hover {
          background: color(primary);
        }
      }
    }

    #edit-submitted-file-ajax-wrapper {
      .alert {
        display: none;
      }
    }

    .upload-fake {
      border-radius: 30px;
      padding: space(.25) space(2);
      font-family: $font-family-base;
    }

    .r-btn.r--primary {
      background: color(primary);
      text-transform: uppercase;
      color: color(light);
    }
  }
}

#edit-rgpd-info {
  margin: 0 auto;
  max-width: 600px + space(2);
  padding: space(2) 0;

  .gr-one-col & {
    padding: 0 space(2);
  }
}

//picto tva tiré du field-text
.tva-be {
  input {
    position: relative;
  }

  .field-prefix {
    border-radius: 4px 0 0 4px;
    bottom: 1px;
    color: color(primary);
    background-color: color(gray, light);
    left: 1px;
    padding: 9px space() 10px;
    position: absolute;
    text-align: center;
    z-index: 1;

    &+input {
      padding: space(0.5) space() space(0.5) space(3.5);
    }
  }
}